import $ from 'jquery';


export default function modal() {

    $('#modal-virtual-tour').on('show.bs.modal', function (event) {
        // Get the button that triggered the modal
        var button = $(event.relatedTarget);

        // Extract value from the custom data-* attribute
        var titleData = button.data('title');
        var srcData = button.data('src');
        $(this).find('.modal-title span').text(titleData);
        $(this).find('.modal-body iframe').attr('src', srcData);
        $(this).find('.modal-body iframe').attr('data-caption', titleData);
    });
}
